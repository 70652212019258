import React from "react"
import Exam from "./Exam"

import { useStaticQuery, graphql } from "gatsby"

const Examlist = () => {
  const { exams } = useStaticQuery(getExams)

  return (
    <div>
      {exams.edges.map(({ node }) => {
        return <Exam key={node.id} exam={node} />
      })}
    </div>
  )
}

const getExams = graphql`
  {
    exams: allContentfulExam(sort: { fields: published, order: DESC }) {
      edges {
        node {
          id
          brand
          manufacturer
          slug
          published(formatString: "MMMM Do, Y")
          origin
          regulations
          style
          gargano
          material
          distillation
          aging
          proof
          sugar
          additives
          aroma
          taste
          afterburn
          examaroma
          examtaste
          examafterburn
          price
          photo {
            fluid(maxWidth: 1200) {
              ...GatsbyContentfulFluid_tracedSVG
            }
            file {
              url
            }
          }
          website
          pretext {
            json
          }
        }
      }
    }
  }
`

export default Examlist
