import React from "react"
import { Link } from "gatsby"

import ReactImageZoom from "react-image-zoom"

import styled from "styled-components"

import Profile from "./Profile"
import Brand from "./Brand"

const Exam = ({ exam }) => {
  const {
    brand,
    manufacturer,
    slug,
    origin,
    regulations,
    style,
    gargano,
    price,
    photo,
  } = exam

  return (
    <ExamWrapper>
      <Brand brand={brand} slug={slug} />

      <Profile
        manufacturer={manufacturer}
        price={price}
        origin={origin}
        regulations={regulations}
        style={style}
        gargano={gargano}
      />

      <Zoomer>
        <ReactImageZoom
          img={photo.fluid.src}
          zoomPosition="original"
          width={300}
          zoomWidth={300}
        />
      </Zoomer>

      <LinkToPost to={`/${slug}`}>
        <Goto>read &#8278; review</Goto>
      </LinkToPost>
    </ExamWrapper>
  )
}

const ExamWrapper = styled.div`
  max-width: 90%;
  margin: 0 auto 5rem;

  display: grid;
  align-items: center;

  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "examdate examdate"
    "brand brand"
    "profile zoomer"
    "linktopost linktopost";

  @media (max-width: 600px) {
    grid-template-rows: 1fr;

    grid-template-areas:
      "examdate"
      "brand"
      "profile"
      "zoomer"
      "linktopost";
  }
`
const Zoomer = styled.div`
  grid-area: zoomer;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto 2rem;

  @media (min-width: 600px) {
    margin-top: 2rem;
    width: 100%;
  }
`

const LinkToPost = styled(Link)`
  grid-area: linktopost;

  cursor: pointer;
  text-decoration: none;
  text-align: center;

  margin: 2rem auto 3rem;
  width: 75%;
`

const Goto = styled.div`
  cursor: pointer;
  margin: 0 auto;
  width: 40%;

  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;

  /* font-family: var(--poiret), sans-serif; */
  font-family: "Poiret One", sans-serif;
  font-weight: bold;
  font-size: 1.5rem;

  outline: 0;
  grid-gap: 8px;

  color: #212121;
  background: 0 0;
  border: 1px solid #212121;
  border-radius: 4px;
  box-shadow: 4px 4px 0 #212121;

  overflow: hidden;
  gap: 8px;
  white-space: nowrap;

  padding: 0.75rem 1rem;
  text-decoration: none;
  text-overflow: ellipsis;

  transition: all 0.14s ease-out;

  :hover {
    box-shadow: 0 0 #212121;
    transform: translate(4px, 4px);
    text-shadow: 0 0 1px black, 0 0 1px black;
    transition: all 0.14s ease-out;
  }
  :focus-visible {
    outline-offset: 1px;
  }

  @media (max-width: 500px) {
    font-size: 1.1rem;
    width: 90%;
    height: 2.25rem;
  }

  @media (min-width: 500px) and (max-width: 600px) {
    font-size: 1.25rem;
    width: 80%;
    height: 2.25rem;
  }

  @media (min-width: 600px) and (max-width: 799px) {
    font-size: 1.5rem;
    width: 70%;
    height: 2.5rem;
  }

  @media (min-width: 800px) and (max-width: 1200px) {
    width: 70%;
  }
  /* display: block;
  margin: 0 auto;
  width: 80%;
  position: relative;

  height: 3rem;
  cursor: pointer;
  transition: 0.25s;

  font-family: var(--montserrat);
  font-size: 2rem;
  line-height: 2;
  text-transform: uppercase;

  color: black;
  outline: none;

  &::after,
  &::before {
    content: "";
    position: absolute;
    height: 140%;
    width: 50%;
    transform: skewX(-5deg);
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    z-index: -2;
  }
  &::before {
    background-color: #fcb712;

    top: -0.75rem;
    left: 0rem;
  }
  &::after {
    background-color: #fedc00;

    top: 0.25rem;
    left: 10rem;
  }
  &:hover {
    &::before,
    &::after {
      top: 0;
      transform: skewx(0deg);
    }
    &::after {
      left: -1rem;
      border: none;
    }
    &::before {
      left: 14.25rem;
      border: none;
    }
  }

  @media (max-width: 600px) {
    width: 90%;
    font-size: 1.5rem;
    height: 2rem;

    &::after,
    &::before {
      width: 70%;
    }
    &::before {
      top: -0.5rem;
      left: -1.5rem;
    }
    &::after {
      top: 0.5rem;
      left: 6rem;
    }
    &:hover {
      &::after {
        left: -2rem;
      }
      &::before {
        left: 6rem;
      }
    }
  }

  @media (min-width: 601px) and (max-width: 899px) {
    width: 100%;

    &:hover {
      &::after {
        left: 4rem;
      }
      &::before {
        left: 11rem;
      }
    }
  } */
`

export default Exam
