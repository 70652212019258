import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"

import Examlist from "../components/Examlist"

const IndexPage = () => (
  <Layout>
    <SEO title="RumExam | exam rum" />

    <Examlist />
  </Layout>
)

export default IndexPage
